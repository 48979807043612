<template>
  <div class="management-sidebar">
    <div class="back-button-container">
      <router-link :to="`/${teamName}/projects`">
        <i class="fa fa-chevron-left" /> {{t('common:button.back')}}
      </router-link>
    </div>
    <h1 class="sidebar-title">Management</h1>
    <div class="search-container">
      <Input
        v-model="q"
        :placeholder="t('common:form.searchBy', { item: t('glossary:menu')})"
        isSearchInput
      />
    </div>
    <div class="sidebar-section-container">
      <div v-for="sidebarItem in filteredSidebarItems" :key="sidebarItem.key">
        <div class="sidebar-section" v-if="canShowSection(sidebarItem)">
          <div
            class="section-title"
            @click="() => {
              if (sidebarItem.path) {
                router.push(`/${teamName}/manage/${sidebarItem.path}`);
              } else if (!sidebarItem.nonCollapsible) {
                onToggleCollapse(sidebarItem.key)
              }
            }"
          >
            <i :class="`section-icon fas ${sidebarItem.sectionIcon}`" />
            {{ sidebarItem.sectionTitle }}
            <span v-if="sidebarItem.badge" style="margin-left: auto;">
              <Badge
                :title="sidebarItem.badge.title"
                :badgeStyle="sidebarItem.badge.badgeStyle"
                :theme="sidebarItem.badge.theme"
                :size="sidebarItem.badge.size"
              />
            </span>
            <i
              v-if="!sidebarItem.nonCollapsible"
              class="fas fa-chevron-down chevron-icon"
              :class="{ 'flip': !isCollapse[sidebarItem.key] }"
            />
          </div>
          <div class="sub-items-container" v-if="!isCollapse[sidebarItem.key] && !sidebarItem.nonCollapsible">
            <div v-for="subItem in sidebarItem.subItems" :key="subItem.path">
              <router-link
                class="link"
                :to="`/${teamName}/manage/${subItem.path}`"
                v-if="canShowSubSection(subItem)"
              >
                <div class="sub-item-title">{{ subItem.title }}</div>
                <div class="description">{{ subItem.description }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-search-container" v-if="isEmptySearch">
        {{t('common:search.resultEmpty.title', { q })}}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BadgeProps } from '@/modules/shared';
import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { canAccessFeature } from '@/middlewares/guard';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import Input from '../../../modules/shared/components/atoms/input/Input.vue';

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const q = ref<string>('');

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);

const teamName = getQueryAsString(route.params, 'teamName');

const isCollapse = ref<Record<string, boolean>>({
  secure: false,
  videoPublishing: false,
  bumper: false,
  subtitle: false,
  videoOutput: false,
  videoInformation: false,
  importSettings: false,
  collection: false,
  plugIn: false,
});

interface SidebarSubItem {
  title: string;
  description: string;
  path: string;
}

interface SidebarItem {
  sectionTitle: string;
  sectionIcon: string;
  key: string;
  subItems?: SidebarSubItem[];
  path?: string;
  nonCollapsible?: boolean;
  badge?: BadgeProps;
}

const secureSubItems = computed<SidebarSubItem[]>(() => {
  const result = [
    {
      title: 'Geo-blocking Rules',
      description: t('teamSettings.sidebar.geoblock.description'),
      path: 'geo-blocking',
    },
    {
      title: 'Allowed/Blocked Websites',
      description: t('teamSettings.sidebar.accessControlPolicy.description'),
      path: 'allowed-blocked-website',
    },
    {
      title: 'Signed URL',
      description: t('teamSettings.sidebar.signedUrl.description'),
      path: 'signed-url',
    },
    {
      title: 'ClearKey Encryption',
      description: t('teamSettings.sidebar.clearKey.description'),
      path: 'clear-key',
    },
  ];

  if (canAccessFeature('multi-drm') && isManagerOrAdmin.value) {
    result.push({
      title: 'Multi-DRM',
      description: t('teamSettings.sidebar.multiDrm.description'),
      path: 'multi-drm',
    });
  }

  return result;
});

const sidebarItems = computed<SidebarItem[]>(() => [
  {
    sectionTitle: 'Secure & Protect',
    sectionIcon: 'fa-shield-alt',
    key: 'secure',
    subItems: secureSubItems.value,
  },
  {
    sectionTitle: 'Video Publishing',
    sectionIcon: 'fa-share-alt',
    key: 'videoPublishing',
    subItems: [
      {
        title: 'Video Embed Players',
        description: t('teamSettings.sidebar.player.description'),
        path: 'players',
      },
      {
        title: 'Playback Channels',
        description: t('teamSettings.sidebar.playbackChannel.description'),
        path: 'playback-channels',
      },
    ],
  },
  {
    sectionTitle: 'Bumper',
    sectionIcon: 'fa-play-circle',
    key: 'bumper',
    permissionRoles: ['user', 'manager'],
    subItems: [
      {
        title: 'Bumpers',
        description: t('teamSettings.sidebar.manageBumper.description'),
        path: 'bumpers',
      },
      {
        title: 'Bumper Insertion',
        description: t('teamSettings.sidebar.applyBumper.description'),
        path: 'bumper-insertion',
      },
    ],
  },
  {
    sectionTitle: 'Subtitle',
    sectionIcon: 'fa-message-captions',
    key: 'subtitle',
    subItems: [
      {
        title: 'Auto-generated Subtitle Profiles',
        description: t('teamSettings.sidebar.subtitleprofile.description'),
        path: 'subtitle-profiles',
      },
      {
        title: 'Bad Word List',
        description: t('teamSettings.sidebar.badwordlist.description'),
        path: 'badword-lists',
      },
    ],
  },
  {
    sectionTitle: 'Video Output',
    sectionIcon: 'fa-video',
    key: 'videoOutput',
    subItems: [
      {
        title: 'Presets',
        description: t('teamSettings.sidebar.preset.description'),
        path: 'presets',
      },
    ],
  },
  {
    sectionTitle: 'Video Information',
    sectionIcon: 'fa-rectangle-list',
    key: 'videoInformation',
    subItems: [
      {
        title: 'Custom Fields',
        description: t('project.manage.customFields.description'),
        path: 'custom-fields',
      },
      {
        title: 'Custom Image Fields',
        description: t('project.manage.customImageFields.description'),
        path: 'custom-image-fields',
      },
    ],
  },
  {
    sectionTitle: 'Import Settings',
    sectionIcon: 'fa-solid fa-file-import',
    key: 'importSettings',
    subItems: [
      {
        title: t('project.manage.videoSources.title'),
        description: t('project.manage.videoSources.description'),
        path: 'video-sources',
      },
    ],
  },
  {
    sectionTitle: 'Collection',
    sectionIcon: 'fa-rectangle-history',
    key: 'collection',
    subItems: [
      {
        title: 'Custom Collection Types',
        description: t('project.manage.collectionType.description'),
        path: 'collection-type',
      },
    ],
  },
  {
    sectionTitle: 'ByteArk Lighthouse Integration',
    sectionIcon: 'fa-chart-line',
    key: 'lighthouse',
    subItems: [
      {
        title: 'Metadata',
        description: t('project.manage.lighthouse.metadata.description'),
        path: 'lighthouse',
      },
    ],
  },
  {
    sectionTitle: 'Plug-in',
    sectionIcon: 'fa-plug rotate',
    key: 'plugIn',
    subItems: [
      {
        title: 'Uploader Forms',
        description: t('teamSettings.sidebar.formUpload.description'),
        path: 'forms',
      },
    ],
  },
  {
    sectionTitle: t('teamSettings.header.title'),
    sectionIcon: 'fa-gear',
    key: 'teamSettings',
    path: 'team-settings',
    nonCollapsible: true,
    badge: {
      title: t('common:default'),
      badgeStyle: 'info',
      theme: 'dark',
      size: 'small',
    },
  },
]);

const filteredSidebarItems = computed<SidebarItem[]>(() => {
  const searchTerm = q.value.toLowerCase();

  if (!searchTerm) {
    return sidebarItems.value;
  }

  const subItemMatcher = (item: SidebarSubItem) => item.title.toLowerCase().includes(searchTerm) || item.description.toLowerCase().includes(searchTerm);

  return sidebarItems.value
    .map((item) => {
      /** เช็กว่า match กับ sectionTitle ของ collapsible section หรือไม่ */
      const sectionTitleMatched = item.sectionTitle.toLowerCase().includes(searchTerm);
      /** เช็กว่า match กับ title หรือ description ของ subItems อย่างน้อย 1 อันหรือไม่ */
      const subItemsMatched = item.subItems?.some(subItemMatcher);

      const matched = subItemsMatched || sectionTitleMatched;

      if (!matched) {
        return undefined;
      }

      return {
        ...item,
        /**
         * ถ้า match เฉพาะ sectionTitle ของ collapsible section ให้แสดง subitems ทั้งหมดของมันออกมาเลย ไม่งั้นมันจะเป็นแค่ collapsible ที่ทำอะไรไม่ได้เลย
         * แต่ถ้า match subitems ก็เอาเฉพาะ submenu ที่ matched กับคำค้นหา
         * */
        subItems: sectionTitleMatched && !subItemsMatched ?
          item.subItems
          : item.subItems?.filter(subItemMatcher),
      };
    })
    /** filter เอา undefined (!matched) ออก (ใช้ flatMap แทน filter เนื่องจาก TypeScript Error) */
    .flatMap((item) => item || []);

  /**
   * Expected Results:
   * ค้นหาว่า "ตั้งค่า" เจอผลลัพธ์:
   *   Video Publishing        <- Collapsible Section
   *     Video Embed Players
   *     ตั้งค่าตัวเล่นวิดีโอ embed    <- Matched
   *     Playback Channels
   *     ตั้งค่าลิงก์วิดีโอและควบคุมสิทธิ์การเข้าชม    <- Matched
   *   Subtitle                <- Collapsible Section
   *     Auto-generated Subtitle Profiles
   *     ตั้งค่า Subtitle profile สำหรับใช้ Auto-generated วิดีโอ   <- Matched
   *   ตั้งค่าเริ่มต้นโปรเจกต์  <- Non-collapsible Section; Matched
   */
});

const isEmptySearch = computed(() => !_.isEmpty(q.value) && _.isEmpty(filteredSidebarItems.value));

function onToggleCollapse(key: string) {
  isCollapse.value[key] = !isCollapse.value[key];
}

function isTeamFeature(key: string) {
  return teamFeatures.value && teamFeatures.value[key];
}

function canShowSubSection(subSection: SidebarSubItem) {
  if (subSection.path === 'custom-image-fields') {
    return isTeamFeature('custom-image');
  }

  if (subSection.path === 'subtitle-profiles') {
    return isTeamFeature('auto-generate-caption');
  }
  return true;
}

function canShowSection(section: SidebarItem) {
  if (section.key === 'bumper') {
    return isTeamFeature('bumper');
  }
  return true;
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.sidebar-title {
  font-size: $font-level-2;
  font-weight: 700;
  padding: 12px 16px;
  margin-bottom: 0;
  color: $grey-800;

  @media screen and (max-width: $max-layout-md) {
    display: none;
  }
}

.back-button-container {
  a {
    color: $grey-500;
    transition: color 150ms ease;

    &:hover {
      color: $grey-800;
      text-decoration: none;
    }
  }
  font-size: 18px;
  padding: 16px 16px 4px;

  i {
    margin-right: 8px;
  }

  @media screen and (max-width: $max-layout-lg) {
    display: none;
  }
}

.management-sidebar {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #FBFBFC;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D0D4D8;
    border-radius: 3px;
  }

  .router-link-exact-active, .router-link-active {
    background-color: $bg-blue;
    color: $bg-navy;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background-color: $ci-primary;
    }
  }

  .sidebar-section-container {
    overflow: auto;
    flex: 1 1 auto;
  }

  .sidebar-section {
    padding-top: 18px;
    border-bottom: 1px solid #E8EAEE;

    .section-title {
      display: flex;
      align-items: center;
      font-size: $font-size-base * 1.2;
      padding-top: 0;
      padding-right: 20px;
      padding-bottom: 16px;
      padding-left: 20px;
      color: $grey-800;
      cursor: pointer;
      text-decoration: none;
      font-weight: $font-weight-bold;

      @media screen and (max-width: $max-layout-xl) {
        padding-left: 16px;
        padding-right: 16px;
      }

      .section-icon {
        font-size: 15px;
        margin-right: $spacing-base * 0.75;
      }
      .rotate {
        transform: rotate(45deg);
      }
      .chevron-icon {
        margin-left: auto;
        transition: all 0.2s ease-in-out;
        &.flip {
          transform: rotate(180deg);
        }
      }
    }

    .sub-items-container {
      animation: show 500ms ease;
      .link {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        position: relative;
        padding-top: 8px;
        padding-right: 16px;
        padding-bottom: 8px;
        padding-left: 48px;

        @media screen and (max-width: $max-layout-xl) {
          padding-left: 44px;
        }
      }
      .sub-item-title {
        color: $grey-600;
        font-weight: $font-weight-bold;
        font-size: $font-size-base * 1.1;
      }
      .description {
        color: $grey-500;
        font-size: $font-size-base;
      }
      @keyframes show {
        from {
          opacity: 0;
          max-height: 0;
        }
        to {
          opacity: 1;
          max-height: 100em;
        }
      }
    }
  }
  .single-section {
    position: relative;
    padding-top: 0;
    .section-title {
      padding-top: $spacing-base * 1.25;

      .icon-cog {
        margin-right: 0.75rem;
      }
    }
  }
}

.project-manage-section {
  position: relative;
  padding-top: 0 !important;

  a {
    padding-top: 18px !important;

    .fas {
      margin-right: $spacing-12;
    }
  }
}

.search-container, .empty-search-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: $spacing-base;
}
</style>
