import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/logo_120.png'


const _hoisted_1 = {
  key: 0,
  class: "page-nav-container"
}
const _hoisted_2 = { class: "collapse-button-container" }
const _hoisted_3 = { class: "back-navigation" }
const _hoisted_4 = { class: "back-page-title" }
const _hoisted_5 = { class: "back-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "page-nav-title"
}
const _hoisted_7 = { class: "page-nav-body" }
const _hoisted_8 = {
  key: 0,
  class: "menu-wrapper",
  initial: { opacity: 0, y: 0 },
  enter: { opacity: 1, y: 0 },
  delay: 100
}
const _hoisted_9 = {
  key: 1,
  class: "menu-wrapper",
  initial: { opacity: 0, y: 5 },
  enter: { opacity: 1, y: 0 },
  delay: 100
}
const _hoisted_10 = {
  key: 2,
  class: "menu-wrapper",
  initial: { opacity: 0, y: 5 },
  enter: { opacity: 1, y: 0 },
  delay: 100
}
const _hoisted_11 = {
  key: 3,
  class: "menu-wrapper",
  initial: { opacity: 0, y: 5 },
  enter: { opacity: 1, y: 0 },
  delay: 100
}
const _hoisted_12 = {
  key: 4,
  class: "menu-wrapper",
  initial: { opacity: 0, y: 5 },
  enter: { opacity: 1, y: 0 },
  delay: 100
}
const _hoisted_13 = { key: 1 }

import { useRoute } from 'vue-router';
import { useBreakpoints } from '@/composables/useBreakpoint';
import {
  ref, computed, watch, toRefs,
} from 'vue';
import { useStore } from '@/store';
import { CollectionNavMenu } from '@/modules/collection';
import CollapseButton from '@/modules/shared/components/atoms/collapseButton/CollapseButton.vue';
import { VideoDetailPageNavMenu } from '@/modules/videoDetail';
import ProjectVideosPageNavMenu from '@/modules/projectVideoList/components/organisms/projectVideosPageNavMenu/ProjectVideosPageNavMenu.vue';
import ProjectListNavMenu from '../projectListNavMenu/ProjectListNavMenu.vue';
import ProjectManagementNavMenu from '../projectManagementNavMenu/ProjectManagementNavMenu.vue';

interface PageNavbarContainerProps {
  isOpen?: boolean;
}

type Page = 'projectList' | 'project' | 'projectManage' | 'video' | 'collection';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageNavBarContainer',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  emits: ['closePageNavBar'],
  setup(__props: any, { emit: __emit }) {

const route = useRoute();
const store = useStore();
const { width } = useBreakpoints();

const emits = __emit;

const props = __props;
const { isOpen } = toRefs(props);

const teamName = computed(() => route?.params?.teamName);
const projectKey = computed(() => route?.params?.projectKey);
const lastNavigatedProjectListPage = computed(() => store.state.global.lastNavigatedProjectListPage);
const currentProject = computed(() => store.state.project.currentProject);

const isCollapse = ref(true);
const isUserToggle = ref(false);
const currentPage = ref<Page>('projectList');
const isResponsive = ref(false);

const isShowing = computed(() => {
  if (currentPage.value === 'projectList') {
    // Won't show on desktop.
    return width.value < 992;
  }

  if (currentPage.value !== 'project') {
    return true;
  }

  if (route.path.includes('all-projects')) {
    return true;
  }

  return currentProject.value !== null;
});

const getPageTitle = () => {
  switch (currentPage.value) {
    case 'video':
      return 'Video Details';
    case 'projectManage':
      return 'Project Management';
    case 'collection':
      return 'Collection Details';
    default:
      return '';
  }
};

const getPreviousPageTitle = () => {
  switch (currentPage.value) {
    case 'video':
      return 'Videos';
    case 'project':
      return 'Projects';
    case 'collection':
      return 'Collections';
    default:
      return 'Back';
  }
};

const getPreviousPageURL = (): string => {
  switch (currentPage.value) {
    case 'video':
      if (route.query?.from === 'all') {
        return `/${teamName.value}/all-projects`;
      }
      return `/${teamName.value}/projects/${projectKey.value}`;
    case 'project':
      return `/${teamName.value}/projects?page=${lastNavigatedProjectListPage.value}`;
    case 'collection':
      return `/${teamName.value}/projects/${projectKey.value}/collections`;
    case 'projectManage':
      return `/${teamName.value}/projects/${projectKey.value}`;
    default:
      return `/${teamName.value}`;
  }
};

const matchPage = (path: string) => {
  if (path.match(new RegExp(`/${teamName.value}/projects/[a-zA-Z0-9-]+/videos`))) {
    currentPage.value = 'video';
  } else if (
    path.match(new RegExp(`/${teamName.value}/projects/[a-zA-Z0-9-]+/collections/[a-zA-Z0-9-]+`))
  ) {
    currentPage.value = 'collection';
  } else if (path.match(`/${teamName.value}/projects/[a-zA-Z0-9-]+/manage`)) {
    currentPage.value = 'projectManage';
  } else if (path.match(new RegExp(`/${teamName.value}/all-projects`))) {
    currentPage.value = 'project';
  } else if (path.match(new RegExp(`/${teamName.value}/projects/[a-zA-Z0-9-]+`))) {
    currentPage.value = 'project';
  } else if (path.match(`/${teamName.value}/projects`)) {
    currentPage.value = 'projectList';
  }
};

const onBackNavLevel = () => {
  switch (currentPage.value) {
    case 'video':
      currentPage.value = 'project';
      break;
    case 'collection':
      currentPage.value = 'project';
      break;
    case 'projectManage':
      currentPage.value = 'project';
      break;
    case 'project':
      currentPage.value = 'projectList';
      break;
    default:
      break;
  }
};

const onClosePageNavBar = () => {
  matchPage(route.path); // reset to current page in case of back
  emits('closePageNavBar');
};

const onUpdatePage = (page: Page) => {
  currentPage.value = page;
};

const onToggleCollapse = () => {
  isCollapse.value = !isCollapse.value;
  isUserToggle.value = true;
};

watch(
  () => route.path,
  () => {
    matchPage(route.path);
  },
  { immediate: true },
);

watch(
  () => width.value,
  () => {
    // prevent default collapse/expand if user toggle navbar
    if (width.value <= 1199 && width.value > 992 && !isUserToggle.value && !isCollapse.value) {
      isCollapse.value = true;
    }
    if (width.value >= 1200 && !isUserToggle.value && isCollapse.value) {
      isCollapse.value = false;
    }
    if (width.value < 992) {
      // no collapse in responsive
      isResponsive.value = true;
      if (isCollapse.value) {
        isCollapse.value = false;
      }
    }
  },
  { immediate: true },
);

watch(
  () => width.value,
  () => {
    // prevent default collapse/expand if user toggle navbar
    if (width.value <= 1199 && width.value > 992 && !isUserToggle.value && !isCollapse.value) {
      isCollapse.value = true;
    }
    if (width.value >= 1200 && !isUserToggle.value && isCollapse.value) {
      isCollapse.value = false;
    }
    if (width.value < 992) {
      // no collapse in responsive
      isResponsive.value = true;
      if (isCollapse.value) {
        isCollapse.value = false;
      }
    }
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_motion = _resolveDirective("motion")!

  return (isShowing.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (currentPage.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["page-nav", {
        collapse: isCollapse.value,
        close: !_unref(isOpen),
        [currentPage.value]: true,
      }])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(CollapseButton, {
                  isCollapse: isCollapse.value,
                  onOnToggleCollapse: onToggleCollapse
                }, null, 8, ["isCollapse"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: getPreviousPageURL(),
                  class: "back-page-level"
                }, {
                  default: _withCtx(() => [
                    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1)),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(getPreviousPageTitle()), 1)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["mobile-back-header-container", { 'first-page': currentPage.value === 'projectList' }])
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (currentPage.value !== 'projectList')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "btn back-button",
                          onClick: onBackNavLevel
                        }, _cache[1] || (_cache[1] = [
                          _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1),
                          _createTextVNode(" Back ")
                        ])))
                      : (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          class: "navbar-brand",
                          to: `/${teamName.value}`
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(["brand-container", { collapse: isCollapse.value }])
                            }, _cache[2] || (_cache[2] = [
                              _createElementVNode("img", {
                                width: "28",
                                height: "28",
                                src: _imports_0,
                                class: "logo",
                                alt: "ByteArk Stream"
                              }, null, -1),
                              _createElementVNode("span", { class: "brand" }, "Stream", -1)
                            ]), 2)
                          ]),
                          _: 1
                        }, 8, ["to"])),
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn close-button",
                      onClick: onClosePageNavBar
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("i", { class: "fas fa-close" }, null, -1)
                    ]))
                  ])
                ], 2)
              ]),
              (getPageTitle())
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(getPageTitle()), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (currentPage.value === 'project')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(ProjectVideosPageNavMenu, {
                        "onUpdate:currentPage": onUpdatePage,
                        isCollapse: isCollapse.value,
                        isResponsive: isResponsive.value
                      }, null, 8, ["isCollapse", "isResponsive"])
                    ])), [
                      [_directive_motion]
                    ])
                  : _createCommentVNode("", true),
                (currentPage.value === 'video')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_unref(VideoDetailPageNavMenu), { isCollapse: isCollapse.value }, null, 8, ["isCollapse"])
                    ])), [
                      [_directive_motion]
                    ])
                  : _createCommentVNode("", true),
                (currentPage.value === 'projectList')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(ProjectListNavMenu)
                    ])), [
                      [_directive_motion]
                    ])
                  : _createCommentVNode("", true),
                (currentPage.value === 'collection')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_unref(CollectionNavMenu), { isCollapse: isCollapse.value }, null, 8, ["isCollapse"])
                    ])), [
                      [_directive_motion]
                    ])
                  : _createCommentVNode("", true),
                (currentPage.value === 'projectManage')
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(ProjectManagementNavMenu, { isCollapse: isCollapse.value }, null, 8, ["isCollapse"])
                    ])), [
                      [_directive_motion]
                    ])
                  : _createCommentVNode("", true)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_13))
}
}

})